import { HTMLDivProps, Icon, IconName, MaybeElement } from "@blueprintjs/core";
import classNames from "classnames";

import { Text, TextVariant } from "src/ui/Text";

import styles from "./SelectItem.module.scss";

export type SelectItem<T> = {
  slug: T;
  label: string;
  icon?: IconName | MaybeElement;
  description?: string;
};

type SelectItemProps<T> = SelectItem<T> &
  HTMLDivProps & {
    active?: boolean;
  };

export const SelectItem = <T,>({ label, icon, description, active, ...wrapperProps }: SelectItemProps<T>) => {
  return (
    <div {...wrapperProps} className={classNames(styles.selectItem, wrapperProps.className, { [styles.active]: active })}>
      <Icon icon={icon} className={styles.icon} />
      <div className={styles.textBox}>
        <Text className={styles.selectItemText} variant={TextVariant.Body}>
          {label}
        </Text>
        <Text variant={TextVariant.Caption}>{description}</Text>
      </div>
    </div>
  );
};
