import { FocusEvent, useState } from "react";
import { InputGroup as BPInputGroup, InputGroupProps } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { withDataTestId } from "@components/Shared/WithDataTestId";
import { ElementType } from "@utilities/E2EUtils";

import "./InputGroup.module.scss";
import styles from "./InputGroup.module.scss";

interface IInputGroupProps extends InputGroupProps {
  disableOutline?: boolean;
}

const InputGroup = (props: IInputGroupProps) => {
  const { className, onFocus, onBlur, disableOutline, ...restProps } = props;
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    if (!restProps.readOnly) {
      setIsFocused(true);
      onFocus?.(e);
    }
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    onBlur?.(e);
  };

  return (
    <BPInputGroup
      className={classNames(styles.inputGroup, className, { [styles.focused]: isFocused, [styles.disabledOutline]: disableOutline })}
      onFocus={handleFocus}
      onBlur={handleBlur}
      data-form-type="other" // Ignores Dashlane autofill
      {...restProps}
    />
  );
};

export default withDataTestId(observer(InputGroup), ElementType.Input);
