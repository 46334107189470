// production https://app.frigade.com/flows/flow_kmWj0WRf5JcQSf1U/overview
// development https://app.frigade.com/flows/flow_XhzIFglrkAoXjAHR/overview

import * as Frigade from "@frigade/react";

import { FRIGADE_API_KEY } from "src/lib/Frigade/constants";
import { GOVCLOUD_MODE } from "src/lib/GovCloud";

import "./Survey.scss";

const FrigadeSurvey = () => {
  const flowId = import.meta.env.VITE_ENV_TYPE !== "production" ? "flow_XhzIFglrkAoXjAHR" : "flow_kmWj0WRf5JcQSf1U";

  return FRIGADE_API_KEY && !GOVCLOUD_MODE ? <Frigade.Survey.NPS className="frigade-survey" flowId={flowId} /> : null;
};

export default FrigadeSurvey;
