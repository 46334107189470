import { ReactElement } from "react";
import { IconName } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { ModuleColorfulIcon, ModuleMonochromeIcon } from "@components/CustomIcon";
import { EntityIcon } from "@components/EntityIcon";
import { RemoveFavoriteButton } from "@components/RemoveFavoriteButton";
import { IconSource, IIcon } from "@rollup-types/icons";
import { EPageName } from "@router/hooks/useAppNavigate";
import { INavLinkAsMenuItem } from "@router/navigation/NavLinkAsMenuItem";
import { NavLinkBackgroundColor } from "@router/navigation/NavLinkAsMenuItem.types";
import appStore from "@store/AppStore";
import { IFavoriteModule } from "@store/FavoriteModuleStore";
import { FeatureFlag } from "@store/FeatureFlagsStore";
import { IWorkspace } from "@store/WorkspaceStore";
import { addPrefixToE2EIdentifiers, IE2EIdentifiersParam } from "@utilities/E2EUtils";
import { isFieldDefined } from "@utilities/TypeGuards";

export interface TNavigationLinkConfig extends IE2EIdentifiersParam {
  text: string;
  icon?: (IconName | ReactElement) | IIcon;
  to: string;
  nestedLinks?: Array<Omit<TNavigationLinkConfig, "nestedLinks">>;
  isDisabled?(): boolean;
}

export type TNavigationLinkConfigs = Array<TNavigationLinkConfig>;

export const getHardwareCatalogLayoutMenuItems = (e2ePrefix?: string): TNavigationLinkConfigs => {
  return [
    {
      text: "Item catalog",
      icon: { source: IconSource.Custom, name: ModuleMonochromeIcon.ItemCatalog },
      to: EPageName.ItemCatalog,
      e2eIdentifiers: addPrefixToE2EIdentifiers("item-catalog", e2ePrefix),
    },
    {
      text: "BOM",
      icon: { source: IconSource.Custom, name: ModuleMonochromeIcon.Bom },
      to: EPageName.Bom,
      e2eIdentifiers: addPrefixToE2EIdentifiers("bom", e2ePrefix),
    },
    {
      text: "PDM Configuration",
      icon: { source: IconSource.Blueprint, name: IconNames.Cog },
      to: EPageName.Settings,
      e2eIdentifiers: addPrefixToE2EIdentifiers("pdm-config", e2ePrefix),
    },
  ];
};

const getPageRootUrl = (page: string) => {
  if (appStore.workspaceModel?.id) {
    return `/workspaces/${appStore.workspaceModel?.id}/${page}`;
  }

  return page;
};

const getFavoritesNavMenuItem = (params: {
  favorites: IFavoriteModule;
  isSidebarOpen: boolean;
  toggleSidebar(): void;
}): INavLinkAsMenuItem => {
  const { favorites, isSidebarOpen, toggleSidebar } = params;

  const handleToggleExpand = () => {
    if (isSidebarOpen) {
      appStore.env.toggleFavoritesExpanded();
      return;
    }
    toggleSidebar();
    if (!appStore.env.isFavoritesExpanded) {
      appStore.env.toggleFavoritesExpanded();
    }
  };

  return {
    text: "Favorites",
    icon: IconNames.Star,
    to: "#",
    nestedLinks: favorites.aliveFavorites.filter(isFieldDefined("entityTypePage")).map(favorite => {
      const isActive = favorite.isActive(window.location.pathname);
      return {
        to: `${getPageRootUrl(favorite.entityTypePage)}/${favorite.entityId}`,
        selected: isActive,
        text: favorite.label,
        e2eIdentifiers: [`favorite-${favorite.label}`],
        icon: <EntityIcon id={favorite.entityId} entityType={favorite.entityType} active={isActive} />,
        renderRightElements: (className: string) => (
          <RemoveFavoriteButton favorite={favorite} className={className} onRemoveFavorite={favorites.removeFavorite} />
        ),
      };
    }),
    expandedInitialState: appStore.env.isFavoritesExpanded,
    onToggleExpand: handleToggleExpand,
    dividerBelow: true,
    collapseIfSidebarCollapsed: true,
    e2eIdentifiers: ["favorites"],
  };
};

const getProjectManagementMenuItem = (currentPage?: string): INavLinkAsMenuItem => ({
  text: "Project Management",
  icon: { source: IconSource.Custom, name: ModuleColorfulIcon.ProjectManagement },
  to: `/${EPageName.ProjectManagement}`,
  activeBgColor: NavLinkBackgroundColor.Violet,
  showInactiveBgColor: true,
  selected: currentPage === EPageName.ProjectManagement,
  tooltip: { title: "Project Management", content: "Create and management projects" },
  dividerBelow: true,
  e2eIdentifiers: ["pm"],
});

interface IGetMainNavMenuItemsParams {
  workspace: IWorkspace | null;
  isSidebarOpen: boolean;
  currentPage?: string;
  toggleSidebar(): void;
}

export const getMainNavMenuItems = (params: IGetMainNavMenuItemsParams): INavLinkAsMenuItem[] => {
  const { workspace, currentPage, isSidebarOpen, toggleSidebar } = params;

  return [
    {
      text: "Inbox",
      icon: { source: IconSource.Custom, name: ModuleColorfulIcon.Inbox },
      to: `/${EPageName.Inbox}?view=inbox${
        appStore.env.inboxSettings.selectedWorkspaceId && `&ws=${appStore.env.inboxSettings.selectedWorkspaceId}`
      }`,
      activeBgColor: NavLinkBackgroundColor.Green,
      showInactiveBgColor: true,
      selected: currentPage === EPageName.Inbox,
      tooltip: { title: "Inbox", content: "Read and respond to mentions and notifications" },
      e2eIdentifiers: ["inbox"],
    },
    ...(appStore.env.featureFlags.enabled(FeatureFlag.PROJECT_MANAGEMENT) ? [getProjectManagementMenuItem(currentPage)] : []),
    ...(workspace ? [getFavoritesNavMenuItem({ favorites: workspace.favorites, isSidebarOpen, toggleSidebar })] : []),
    {
      text: "Modeling",
      icon: { source: IconSource.Custom, name: ModuleColorfulIcon.Modeling },
      to: getPageRootUrl(EPageName.Modeling),
      activeBgColor: NavLinkBackgroundColor.Red,
      showInactiveBgColor: true,
      selected: currentPage === EPageName.Modeling,
      tooltip: { title: "Modeling", content: "System modeling with blocks and properties" },
      e2eIdentifiers: ["modeling"],
    },
    {
      text: "Requirements",
      icon: { source: IconSource.Custom, name: ModuleColorfulIcon.Requirements },
      to: getPageRootUrl(EPageName.Requirements),
      activeBgColor: NavLinkBackgroundColor.Indigo,
      showInactiveBgColor: true,
      selected: currentPage === EPageName.Requirements,
      tooltip: "Requirements",
      e2eIdentifiers: ["requirements"],
    },
    {
      text: "Analysis",
      icon: { source: IconSource.Custom, name: ModuleColorfulIcon.Analysis },
      to: getPageRootUrl(EPageName.Analysis),
      activeBgColor: NavLinkBackgroundColor.Lime,
      showInactiveBgColor: true,
      selected: currentPage === EPageName.Analysis,
      tooltip: { title: "Analysis", content: "Create and run analyses" },
      e2eIdentifiers: ["analysis"],
    },
    {
      text: "Knowledgebase",
      icon: { source: IconSource.Custom, name: ModuleColorfulIcon.Knowledgebase },
      to: getPageRootUrl(EPageName.Knowledgebase),
      activeBgColor: NavLinkBackgroundColor.Cerulean,
      showInactiveBgColor: true,
      selected: currentPage === EPageName.Knowledgebase,
      tooltip: "Knowledgebase",
      e2eIdentifiers: ["knowledgebase"],
    },
    {
      text: "Product Data Management",
      icon: { source: IconSource.Custom, name: ModuleColorfulIcon.HardwareCatalog },
      to: getPageRootUrl(EPageName.Pdm),
      activeBgColor: NavLinkBackgroundColor.Gray,
      showInactiveBgColor: true,
      selected: currentPage === EPageName.Pdm,
      tooltip: "Product Data Management",
      e2eIdentifiers: ["hardware-catalog"],
    },
  ];
};
